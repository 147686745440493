@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.eye-blue {
  background-image: url("./images/noun-view-1026819.png");
  background-repeat: no-repeat;
  background-position: center;
  padding: 12px;
  display: inline-block;
  background-size: 25px;
  width: 26px;
  height: 24px;
  margin-right: 10px;
}
.header-icons {
  border-radius: 32px;
  display: inline-block;
  border: "1px solid white";
  padding: "0px";
  cursor: pointer;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  background-color: #e9ebeb;
}
.header-img {
  width: 35px;
  height: 35px;
}
.header-icons-cp {
  border-radius: 32px;
  display: inline-block;
  border: "1px solid white";
  padding: "0px";
  cursor: pointer;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  background-color: #e9ebeb;
}
.header-img-cp {
  width: 30px;
  height: 30px;
}

/* .crantbl */

.nstable .thead-dark th {
  color: black !important;
  background-color: #5a5c69;
  border-color: #6c6e7e;
}

.nstable td,
.nstable th {
  padding: 15px 0px 0px 0px !important;
  vertical-align: top;
  border-top: none !important;
}

.nstable > :not(caption) > * > * {
  padding: 0px !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn-close-rd {
  border-radius: 19px !important;
  height: 35px !important;
  width: 35px !important;
}

.tablecrane .thead-dark th {
  color: #fff !important;
  background-color: #b3b3b3;
  border-color: #6c6e7e;
}
.tablecrane th {
  background-color: #b3b3b3 !important;
  color: white !important;
}
.tablecrane thead th {
  background-color: #b3b3b3; /* Change this to your desired color */
  color: white; /* Optional: Change text color for better visibility */
}

.header-txtbx {
  width: 15px;
  height: 18px;
  border: none;
  background-color: #f3f4f5;
  margin: 1px;
}

.header-lbl {
  color: #000;
  font-size: 9px;
}

.Corper-subtxt {
  background-color: #f9d8d2;
  margin-left: -20px;
  margin-right: -20px;
}

.card-subheading {
  color: #e03f20;
  padding: 5px;
  margin: 5px;
}

.protection-sub-bg-color {
  background-color: #cbd1d0;
  font-weight: bold;
}

.protection-sub-color {
  color: #000;
}

.wenv-bg-color {
  background-color: #99a4a2;
  color: white;
}

.wenv-sub-bg-color {
  background-color: #cbd1d0;
  color: #000;
}

.change-bg-color {
  background-color: #df4738;
  color: white;
}

.change-color {
  color: #df4738;
}

.criti-hm-bg-color {
  background-color: #ee9c8d;
  color: white;
}

.criti-hm-color {
  background-color: #ee9c8d;
  color: white;
  margin-right: -23px;
  margin-left: -23px;
  margin-top: -20px;
}

.criti-fr-bg-color {
  background-color: #aee3bf;
  color: black;
}

.criti-fr-color {
  background-color: #aee3bf;
  color: black;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* You can also specify a fixed width or max-width to control the width of the container */
  max-width: 200px;
  /* Adjust as needed */
}

.custom-rounded-top {
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.custom-rounded {
  border-radius: 5px;
}
.btn-rns {
  height: 43px;
}
.form-check-input {
  border: 1px solid #000;
}

.infopng {
  background-image: url("./images/Info-Img.png");
  background-repeat: no-repeat;
  background-position: center;
  padding: 8px;
  display: inline-block;
  background-size: 15px;
  width: 15px;
  height: 10px;
  margin-right: 10px;
}
.phonerining {
  background-image: url("./images/phone-ringing.png");
  background-repeat: no-repeat;
  background-position: center;
  padding: 12px;
  display: inline-block;
  background-size: 25px;
  width: 26px;
  height: 24px;
  margin-right: 10px;
}

.firepng {
  background-image: url("./images/FireAccordionImg.png");
  background-repeat: no-repeat;
  background-position: center;
  padding: 8px;
  display: inline-block;
  background-size: 30px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.f-r-bg-color {
  background-color: #34b85f;
}
.cb-size {
  width: 12px;
  margin: 0px 5px;
}
.cb-size-ms {
  width: 20px;
  margin: 0px 5px;
}
.cb-size-cp {
  width: 8px;
  margin: 0px 5px;
}
/*


/************************************************************************************************************************/
/* Set the page size to A4 */
@page {
  size: A4;
  margin: 10px;
}

.corepermit-style {
  width: 100%;
  margin: 0px;
  font-size: 9px !important;
  padding: 5px;
}

.label-height {
  line-height: 2px !important;
}
.twolabel-height {
  line-height: 0.9 !important;
}
.form-checks {
  min-height: 1rem !important;
}
.col-form-label
/* Hide elements that should not be printed */
.no-print {
  display: none;
}

.sub-header-web {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  background-color: #000;
  color: white;
  margin-top: -18px;
  border: none;
}

page[size="A4"] {
  background: white;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
}
.printpage {
  background: white;
  width: 21cm;
  height: 29.7cm;
}
.liftimg {
  height: 268px;
  width: 344px;
}
.cb-mt-5px {
  margin-top: 0px !important;
}
.cew {
  width: 100%;
  margin: 0px;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 10px !important;
}
.cb-txtbx-border-none {
  border: none;
  border-bottom: 1px solid #000;
  margin: 0px;
  padding: 0px;
  height: 13px;
  font-size: 9px !important;
  background: transparent;
}
/*****************************************************CORE PERMIT****************************************************************/
.core-card-icons {
  border-radius: 32px;
  display: inline-block;
  border: 1px solid white;
  padding: 0px;
  cursor: pointer;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.core-card-icon-size {
  width: 15px;
  height: 15px;
}
/*********************************************************************************************************************************/
/***************************************************************HOT WORK PERMIT*******************************************************/
.hwptbl {
  margin-bottom: 0px !important;
}
.hwptbl .thead-dark th {
  color: #423333 !important;
  background-color: #ffd3c4 !important;
  border-color: #6c6e7e;
  padding: 0px !important;
  border-right: 2px solid #fff;
}
.ame .thead-dark th {
  color: #fff !important;
  background-color: #7fd29a !important;
  border-color: #6c6e7e;
  padding: 0px !important;
  border-right: 2px solid #fff;
}

.ame > :not(caption) > * > * {
  padding: 1px !important;
  color: var(
    --bs-table-color-state,
    var(--bs-table-color-type, var(--bs-table-color))
  );
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px
    var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.fw {
  border: 1px solid #fff !important;
}
.fw .thead-dark th {
  color: #fff !important;
  background-color: #ffd3c4 !important;
  border-color: #6c6e7e;
  padding: 0px !important;
  border-right: 2px solid #fff;
}
.ar .thead-dark th {
  color: #fff !important;
  background-color: #7fd29a !important;
  border-color: #6c6e7e;
  padding: 0px !important;
  border-right: 2px solid #fff;
}
.partthree .thead-dark th {
  color: #000 !important;
  background-color: #c2c9c8 !important;
  border-color: #6c6e7e;
  padding: 0px !important;
  border-right: 2px solid #fff;
}
.fw > :not(caption) > * > * {
  padding: 1px !important;
  color: var(
    --bs-table-color-state,
    var(--bs-table-color-type, var(--bs-table-color))
  );
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px
    var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.hwptbl thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e3e6f0;
  border-bottom-color: rgb(227, 230, 240);
}
hwptbl td,
.table th {
  margin: 0px !important;
  padding: 3px !important;
}
.ame {
  border: 1px solid #fff !important;
}
.hwptbl {
  border: 1px solid #fff !important;
}

.lbl-bg-color-hwp-hwi {
  background-color: #d2efdc;
}
/**********************************************************************************************************************/
/* Customize fonts, colors, and other styles as needed for printing */

/************************************************************************************************************************/

/**********************************************************CRITICAL ELEVATED*********************************************/
.pagebreak {
  height: 0px;
}
.lbl-bg-color-cew {
  background-color: #e0e0e1;
}
.cew-padding-hi {
  padding: 0px 5px 0px 0px !important;
}
.label-height-cew {
  line-height: 12px !important;
}
/************************************************************************************************************************/

/**********************************************************EXCAVATION permit*********************************************/

.lbl-bg-color-ep {
  background-color: #d4d4d4;
}

/************************************************************************************************************************/
.frm-grp-mb {
  margin-bottom: 2px;
}
.frm-grp-mb-cp {
  margin-bottom: 1px;
}
.lbl-bg-color {
  background-color: #babefc;
}
.lbl-bg-color-crane-bd {
  background-color: #d0d0d0;
}
.lbl-bg-color-crane-ei {
  background-color: #fcd9ca;
}
.lfttbl1 .table .thead-dark th {
  color: #fff;
  background-color: #5a5c69 !important;
  border-color: #6c6e7e;
  margin-bottom: 0px;
}

.txt-bx-col-12-height {
  height: 38px;
}

.CorePermit_Web-form {
  font-size: 12px;
}

.eye-blue {
  background-image: url("./images/noun-view-1026819.png");

  background-repeat: no-repeat;

  background-position: center;

  padding: 12px;

  display: inline-block;

  background-size: 25px;

  width: 26px;

  height: 24px;

  margin-right: 10px;
}
/*******************************************/
.loader-container-ms {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-radius: 5px 0 0 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  top: 0%;
}

.euipment {
  margin-bottom: 29px;
}
.hazards-mg {
  margin: 21px -12px 23px -12px;
}
/******************************************/

@media print {
  body,
  page[size="A4"] {
    box-shadow: 0;
  }
  /* @page {
    size: A4;
    margin: 10px;
  }   */
  .euipment {
    margin-bottom: 20px;
  }
  .hazards-mg {
    margin: 6px -12px 23px -12px;
  }

  .liftimg {
    height: 275px;
    width: 245px;
  }
  .bodys {
    font-size: 10px !important;
  }
  .pagebreak {
    height: 50px !important;
  }

  body {
    font-size: 12px;
  }
  .bodys {
    font-size: 10px !important;
  }

  .cew {
    width: 100%;
    margin: 0px;
    font-size: 10px !important;
    /* font-weight: 601 !important; */
    padding: 10px !important;
  }
  /* Target specific elements for styling */
  .print-header {
    font-weight: bold;
  }
}
/*Print end*/

/********************************************POPUPS-14-10-2023*************************************************/

#nsmodel .modal-content {
  width: 85%;
  display: block;
  margin: 0 auto;
}
#nsmodel .modal-body {
  width: 85%;
  display: block;
  margin: 0 auto;
}
#nsmodel h5 {
  font-size: 15px;
}
.ppopup {
  top: 15%;
}
.popupicons {
  width: 70px;
  height: 70px;
}

.core-evi-lbl-bg {
  background-color: #d6f1df;
}
.core-ppl-lbl-bg1 {
  background-color: #cce1f2;
}
.core-ppl-lbl-bg2 {
  background-color: #a0c7e7;
}
.core-ppl-lbl-bg3 {
  background-color: #2c2e35;
  color: #fff;
}
.core-ppl-lbl-bg4 {
  background-color: #d1d6d5;
  color: #000;
}
.core-engy-lbl-bg {
  background-color: #d1dbee;
  color: #000;
}
.core-equip-lbl-bg {
  background-color: #ffe3d5;
  color: #000;
}
.core-chem-lbl-bg {
  background-color: #f9d8d2;
  color: #000;
}
.core-prote-lbl-bg {
  background-color: #f9d8d2;
  color: #000;
}
.core-we-lbl-bg1 {
  background-color: #99a4a2;
  color: #fff;
}
.core-train-lbl-bg1 {
  background-color: #b5baba;
  color: #000;
}
.core-change-lbl-color {
  color: #df3a1c;
}
.core-change-issuer-lbl-bg {
  background-color: #5c6565;
  color: #fff;
}
.core-checkbox-lbl-bg {
  background-color: #f3f5f6;
}

.microsite-txtbx {
  width: 13px;
  height: 13px;
  border: 1px solid #ced4da;
  margin: 1px;
  border-radius: 0.2rem;
  font-size: 10px;
  text-align: center;
  padding: 0px !important;
}
.datePicker {
  margin-left: 4px;
}
.microsite-txtbxdate {
  width: 70px;
  height: 13px;
  border: 1px solid #ced4da;
  margin: 1px;
  border-radius: 0.2rem;
  font-size: 10px;
  text-align: center;
  padding: 0px !important;
}
/* web */
.microsite-txtbxxdate {
  width: 70px;
  height: 18px;
  border: 1px solid #ced4da;
  margin: 1px;
  margin-left: 3px;
  border-radius: 0.2rem;
  font-size: 10px;
  text-align: center;
  padding: 0px !important;
}
@media print {
  .print-hidden {
    display: none;
  }
}
