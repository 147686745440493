@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.Navbar_shadow {
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1) !important;
}

.Roles_text {
  font-size: 1.29rem !important;
}

.Sub_role_text {
  margin-top: 12px !important;
}

.req {
  margin-left: 120px;
}

.clicked-menu-item {
  background-color: lightgray;
  /* Change to your desired background color */
}

.App {
  text-align: center;
}

.centalStr {
  margin-top: -20px !important;
}

.centalStrAptr {
  margin-top: -20px !important;
}

.rmStrAptr {
  /* margin-top: -20px !important;  */
  /* margin-left: -13px !important; */
}

.name {
  color: black !important;
  font-size: 0.8rem !important;
  font-weight: 700 !important;
}

.user_role {
  font-size: 0.8rem !important;
}

.arrow {
  margin-right: 8px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.userMgt {
  margin-top: -20px !important;
}

.master {
  margin-top: -20px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::placeholder {
  font-size: 0.8rem;
}

.research_card_image {
  width: auto;
  height: 164px;
  margin: -5px !important;
}

.research_card {
  background-color: #ffe294 !important;
}

.MCRC_text {
  font-size: 1.2rem;
  font-weight: 600;
}

.research_text {
  /* width: 87%; */
  font-size: 0.9rem;
}

.arrow_icon {
  top: 1px;
  position: relative;
  width: 10px !important;
  height: 10px !important;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.plus_icon {
  top: 1px;
  position: relative;
  width: 13px !important;
  height: 13px !important;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.status_card {
  flex-wrap: wrap;
}

.status-image {
  width: 48px;
}

.status_text {
  font-size: 0.8rem;
  font-weight: 600;
}

.status_value {
  font-size: 0.9rem;
}

.cenralStore_card {
  background-image: url(./images/centrol_store.png);
  background-position: center;
  background-size: cover;
}

.cenralStore_cards {
  background-image: url(./images/centrlStorCard.png);
  background-position: center;
  background-size: cover;
}

.farmerOptions_card {
  background-image: url(./images/RM_tracking.png);
  background-position: center;
  background-size: cover;
}

.RM_tracking_card {
  background-image: url(./images/farmer_options.png);
  background-position: center;
  background-size: cover;
}

.store_text {
  font-size: 0.86rem;
}

.role_text {
  line-height: 14px;
  font-size: 0.7rem;
}

.logo {
  width: 210px;
}

.border-r-2-md {
  border-right: 2px solid #e5e7eb !important;
}

.font-weight-600 {
  font-weight: 600;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.login_card {
  border: 1px solid rgb(59 130 246) !important;
  border-bottom: 3px solid rgb(59 130 246) !important;
  border-radius: 0px !important;
}

.text-decoration-noneP {
  text-decoration: none;
}

.sign_in_button {
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
  font-weight: 700 !important;
}

.text-gray {
  color: #9ca3b7 !important;
}

.mcrc-input-group-text {
  border-right: transparent !important;
}

.search_input {
  border-left: transparent !important;
}

.search_image {
  width: 15px;
  height: 15px;
}

.search_input::placeholder {
  color: #b5b6b8 !important;
}

.add_button {
  background-color: #0175f2;
  font-size: 0.8rem !important;
  border: 1px solid black !important;
}

.mcrc_hrline {
  margin-top: 0.7rem !important;
  margin-bottom: 0.9rem !important;
  border-top: 1px solid #e3e6f0 !important;
}

.data_tableCard {
  border: 1px solid #e3e6f0 !important;
}

.Content_data_table.dataTable thead th,
.Content_data_table.dataTable thead td {
  border-bottom: 1px solid #ebebed !important;
}

.Content_data_table {
  font-size: 1rem !important;
}

.Content_data_table.dataTable.no-footer {
  border-bottom: 1px solid #ebebed !important;
}

.bg-sky-blue {
  background-color: #4e9ff6 !important;
}

table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: collapse !important;
  border-spacing: 0;
}

td,
th {
  white-space: nowrap !important;
}

.edit_delete_icon {
  font-size: 0.7rem;
  border: 1px solid #ebebed !important;
  padding: 3px 4px !important;
  border-radius: 2px;
}

.activityForRM:hover {
  color: white !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: white !important;
  border: 1px solid #0175f2 !important;
  background-color: #0175f2 !important;
  background: #0175f2 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0.1em 0.7em !important;
  color: white !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #0175f2 !important;
}

.paginate_button.current {
  color: white !important;
  border: 1px solid #0175f2 !important;
  background-color: #0175f2 !important;
  background: #0175f2 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current:hover
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button {
  padding: 0.1em 0.7em !important;
  color: white !important;
}

.submit_button {
  border: 1px solid #0175f2 !important;
  background-color: #0175f2 !important;
  padding-left: 2.9rem !important;
  padding-right: 2.9rem !important;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -2.5rem -1.4rem -1rem auto;
  /* margin-bottom: 20px !important; */
  /* margin-top: -10px !important; */
}

.close_btn {
  text-decoration: none !important;
  border-radius: 50% !important;
  font-size: 1rem !important;
  opacity: 1 !important;
  color: #ffff !important;
  font-weight: 600 !important;
  padding: 3.5px 10.5px !important;
  background-color: red !important;
  margin-top: -20px !important;
  /* margin-left: 40px !important; */
  /* float: inline-end; */
}

.forSelect {
  padding-left: 0 !important;
}

body {
  font-family: "Poppins", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::placeholder {
  font-size: 0.8rem;
}

.research_card_image {
  width: auto;
  height: 164px;
}

.research_card {
  background-color: #ffe294 !important;
}

.MCRC_text {
  font-size: 1.2rem;
  font-weight: 600;
}

.research_text {
  /* width: 87%; */
  font-size: 0.9rem;
}

.arrow_icon {
  top: 1px;
  position: relative;
  width: 10px;
  height: 10px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.plus_icon {
  top: 1px;
  position: relative;
  width: 8px;
  height: 8px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.statuss {
  margin-left: -10px !important;
}

.status_card {
  flex-wrap: wrap;
}

.status-image {
  width: 48px;
}

.status_text {
  font-size: 0.8rem;
  font-weight: 600;
}

.status_value {
  font-size: 0.9rem;
}

.cenralStore_card {
  background-image: url(./images/centrol_store.png);
  background-position: center;
  background-size: cover;
}

.farmerOptions_card {
  background-image: url(./images/RM_tracking.png);
  background-position: center;
  background-size: cover;
}

.RM_tracking_card {
  background-image: url(./images/farmer_options.png);
  background-position: center;
  background-size: cover;
}

.store_text {
  font-size: 0.86rem;
}

.badge {
  background-color: red;
  border-radius: 50% !important;
  padding: 2px 0px 0px 0px !important;
  position: relative;
  top: -9px;
  font-size: 11px !important;
  right: 10px;
  color: white;
  width: 15px;
  height: 16px;
}

.Notification_image {
  width: 18px;
}

.role_text {
  line-height: 14px;
  font-size: 0.7rem;
}

.logo {
  width: 210px;
}

.border-r-2-md {
  border-right: 2px solid #e5e7eb !important;
}

.font-weight-600 {
  font-weight: 600;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.login_card {
  border: 1px solid rgb(59 130 246) !important;
  border-bottom: 3px solid rgb(59 130 246) !important;
  border-radius: 0px !important;
}

.text-decoration-noneP {
  text-decoration: none;
}

.sign_in_button {
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
  font-weight: 700 !important;
}

.text-gray {
  color: #9ca3b7 !important;
}

.mcrc-input-group-text {
  border-right: transparent !important;
}

.search_input {
  border-left: transparent !important;
}

.search_image {
  width: 15px;
  height: 15px;
}

.search_input::placeholder {
  color: #b5b6b8 !important;
}

.add_button {
  /* background-color: #0175F2 !important; */
  font-size: 0.8rem !important;
  /* border: 1px solid #0175F2 !important; */
}

.mcrc_hrline {
  margin-top: 0.7rem !important;
  margin-bottom: 0.9rem !important;
  border-top: 1px solid #e3e6f0 !important;
}

.data_tableCard {
  border: 0px solid #e3e6f0 !important;
}

.Content_data_table.dataTable thead th,
.Content_data_table.dataTable thead td {
  text-align: center;
  border-bottom: 1px solid #ebebed !important;
}

.Content_data_table.dataTable.no-footer {
  border-bottom: 1px solid #ebebed !important;
}

.bg-sky-blue {
  background-color: #4e9ff6 !important;
}

table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: collapse !important;
  border-spacing: 0;
}

.collapse-item {
  width: 170px !important;
}

.collapse-inner a :hover {
  /* background-color: black !important; */

  /* color: white !important; */

  color: black;
  /* width: 170px !important; */
}

table th {
  background-color: #edf5fe !important;
}

td,
th {
  white-space: nowrap !important;
}

.edit_delete_icon {
  font-size: 0.7rem;
  border: 1px solid #ebebed !important;
  padding: 3px 4px !important;
  border-radius: 2px;
}

.paginate_button.current {
  color: white !important;
  border: 1px solid #0175f2 !important;
  background-color: #0175f2 !important;
  background: #0175f2 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active:hover {
  outline: none;
  background-color: #0175f2 !important;
  background: #0175f2 !important;
  box-shadow: inset 0 0 3px #0175f2 !important;
  border: 1px solid #0175f2;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #0175f2 !important;
}

.submit_button {
  border: 1px solid #0175f2 !important;
  background-color: #0175f2 !important;
  padding-left: 2.9rem !important;
  padding-right: 2.9rem !important;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -2.5rem -1.4rem -1rem auto;
}

.close_btn {
  text-decoration: none !important;
  border-radius: 50% !important;
  opacity: 1 !important;
  color: #ffff !important;
  font-weight: 600 !important;
  padding: 2px 10px 4px 10px !important;
  background-color: red !important;
  height: 31px;
}

.modal_title {
  font-size: 1.2rem !important;
}

.bg-gradient-primary {
  background-color: #1580f3;
  background-image: linear-gradient(180deg, #1580f3 10%, #1580f3 100%),
    url("./images/sidemenuBg.png");
}

.horizontal-line {
  width: 50%;
  height: 1px;
  background-color: #1580f3;
  margin: 20px auto;
}

.acc {
  /* width: 800px !important; */
  /* margin-left: 100px; */
  /* margin-top: 40px; */
  font-size: 11px !important;
  border-color: 2px solid red;
}

.spn {
  margin-left: 80px;
}

.status {
  margin-left: 120px;
}

.bal {
  margin-left: 120px;
}

.issueQuantity {
  margin-left: 130px;
}

.card {
  --bs-card-spacer-y: none !important;
  --bs-card-spacer-x: 2rem;
  --bs-card-title-spacer-y: 0.5rem;
  justify-content: center !important;
}

.isu {
  margin-left: 210px;
}

.str {
  font-size: 16px;
  margin-left: 20px;
}

input[type="file"] {
  display: none;
}


h6 {
  font-weight: 50;
  font-size: 16px;
}

.qua {
  font-weight: bold;
  /* margin-right: 500px ; */
}

/* This CSS for the Role Asset Mapping */
.selector {
  padding: 6px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 175px;
  /* margin-left: 20px !important; */
}

/* Style for the item box */
.dropdown-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.checkbox {
  /* margin: 15px; */
  float: right;
  margin-right: 30px;
  padding: 9px;
}

.mainAsset {
  margin-left: 70px;
}

.view {
  float: right;
  padding: 2px;
}

.edit {
  float: right;
  padding: 2px;
}

@media (max-width: 576px) {
  .selector {
    padding: 5px !important;
    font-size: 8px !important;
    border: 1px solid #ccc;
    border-radius: 4px !important;
    margin-bottom: 5px;
    width: 100px !important;
    /* margin-left: 20px !important; */
  }

  .mainAsset {
    margin-left: 8px !important;
    font-size: 8px !important;
    float: left !important;
    margin-right: 10px !important;
  }

  .mainAsset .selector {
    margin-left: 0px !important;
  }

  .role {
    margin-left: 2px !important;
    font-size: 8px;
  }
}

/* Request Order History */

.btns {
  width: 100px;
  height: 39px;
  /* text-align: center !important; */
  justify-content: center;
  background-color: #007aff !important;
  margin-left: 140px;
  color: white !important;
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
}

.sidebar .nav-item .collapse .collapse-inner,
.sidebar .nav-item .collapsing .collapse-inner {
  padding: 0.5rem 0;

  font-size: 0.85rem;
  margin: 0px;
}
.workpermitTransfer {
  width: 70px;
  height: 25px;
  justify-content: center;
  background-color: #FFA500 !important;
  color: white !important;
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  padding-top: revert;
  padding-left: 15px;
}
.workpermitApprove {
  width: 70px;
  height: 25px;
  justify-content: center;
  background-color: #007aff !important;
  color: white !important;
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  padding-top: revert;
  padding-left: 15px;
}

.workpermitReject {
  width: 70px;
  height: 25px;
  text-align: center !important;
  justify-content: center;
  background-color: #d50101 !important;
  margin-left: 140px;
  color: white !important;
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  padding-top: revert;
  padding-left: 15px;
}

.workpermitView {
  width: 70px;
  height: 25px;
  justify-content: center;
  background-color: #00cb0e !important;
  color: white !important;
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  padding-top: revert;
  padding-left: 15px;
}

.btnsReject {
  top: 741px;
  left: 1541px;
  width: 100px;
  height: 39px;
  text-align: center !important;
  justify-content: center;
  background-color: #d50101 !important;
  margin-left: 140px;
  color: white !important;
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  opacity: 1;
}

.AproveBtn {
  width: 70px;
  height: 25px;
  justify-content: center;
  background-color: #00cb0e !important;
  color: white !important;
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  padding-top: revert;
  padding-left: 15px;
}

.AproveBtnHistorty {
  top: 741px;
  left: 1541px;
  width: 100px;
  height: 39px;
  /* text-align: center !important; */
  justify-content: center;
  background-color: #00cb0e !important;
  /* margin-left: 140px; */
  color: white !important;
  font-size: 13px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  opacity: 1;
}

.close {
  background-color: red !important;
}

.strt {
  font-size: 16px;
  margin-left: 115px;
}

.request-card {
  padding: 24px;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
}

.loader-containerBg img {
  background: transparent !important;
}

.btn-sm {
  width: 80px !important;
}

/* Request Order History */

/* .spnn{
  margin-left: 180px;
 } */
.returnQuantity {
  margin-left: 90px;
}

.return {
  margin-left: 235px;
}

.buttons {
  width: 120px !important;
}

.btnn {
  width: 90px !important;
}

.btnReject {
  width: 100px;

  text-align: center !important;

  justify-content: center;

  background-color: #dc4c64 !important;

  /* margin-left: 140px; */

  color: white !important;

  font-size: 14px;

  padding: 7px 10px;

  border: none;

  border-radius: 0.2rem;
}

.close {
  background-color: red !important;
}

.strt {
  font-size: 16px;

  margin-left: 115px;
}

.prd {
  margin-left: 127px !important;
}

.btn-sm {
  width: 180px !important;
}


.loader-container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-radius: 5px 0 0 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  /* Adjust the opacity as needed */
  z-index: 9999;
  /* Make sure the loader is on top of other elements */
}

.loader-container_bulk {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border-radius: 5px 0 0 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  /* Adjust the opacity as needed */
  z-index: 9999;
  /* Make sure the loader is on top of other elements */
}

.dashboard {
  margin-top: 3 !important;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
  border: 1px solid;
}

.collapse-inner a :hover {
  /* background-color: black !important; */

  /* color: white !important; */

  color: black;

  /* width: 150px !important; */
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #262b3a;
  opacity: 0.9;
}

/* .loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.userProfilePic {
  max-width: 100px !important;
  /* Adjust this value as needed */
  max-height: 100px !important;
  /* Adjust this value as needed */
  overflow: hidden !important;
}

.userProfilePic img {
  max-width: 100px !important;
  /* Adjust this value as needed */
  max-height: 100px !important;
}

.loader-img {
  width: 75px;
  /* Add any additional styles for the loader image */
}


/* Login Css */
.passIcon {
  display: flex;
  position: relative;
  top: 0%;
  transform: translateY(-190%);
  height: 12px;
  float: right;
  padding-right: 10px;
  /* transform: translateX(50%); */
}

.bgImage {
  background-image: url("../src/images/loginBgImage.png");
  /* height: 600px !important; */
  /* width: 950px; */
  justify-content: center !important;
  background-size: cover;
  /* margin-top: 5%;
margin-left: 12%; */
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.show {
  opacity: none !important;
}

.mcrcResearch {
  margin-top: -15px;
  margin-left: 2px;
}

.mcrcbtn {
  position: relative !important;
  border: 1px solid white !important;
  border-width: 5px;
  background-color: white;
  height: 15px;
  width: 15px;
  cursor: pointer;
  border: none !important;
  float: right;
  padding-left: 3px;
  /* margin-left: -150px !important; */
}

.logos {
  margin-left: 6px;
  padding: 0px;
  /* margin-top: 2px; */
  margin-right: 2px;
}

div .logos {
  margin-right: 5px;
}

.active-link {
  background-color: #fff;
  /* Set your desired background color */
  color: #000;
}

.modal {
  --bs-modal-zindex: 1050;
}

.AdminName {
  float: right !important;
  margin-left: 57px !important;
  margin-top: -30px !important;
}

.userN {
  margin-top: -15px !important;
}

#accordionSidebar {
  background-image: url("../src/images/sidemenuBg.png");
  background-size: 140px !important;
  background-repeat: no-repeat;
  float: var(--bs-modal-zindex);
  /* text-align: center !important; */
  background-position: bottom !important;
}

.modalBlur {
  --bs-modal-zindex: 1155 !important;
}

.ProductName {
  grid-template-columns: repeat(4, 1fr);
}

.ProductNameForRandM {
  grid-template-columns: repeat(3, 1fr);
}

.forheaders {
  grid-template-columns: repeat(4, 1fr);
}

.ProductNameHeader {
  grid-template-columns: repeat(5, 1fr);
}

.ProductNameHeaderEq {
  grid-template-columns: repeat(3, 1fr);
}

.ProductNameHeaderOrder {
  grid-template-columns: repeat(4, 1fr);
}

.close {
  position: absolute;
  right: 15px;
  top: 7px;
}

.App {
  text-align: center;
  margin: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.boxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 10%;
  left: 15.5%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 0.3s ease-in-out;
}

.boxContainer input[type="text"] {
  padding: 10px;
  margin: 5px;
  font-size: 16px;
  border-radius: 5px;
}

.boxContainer button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
}

.remark {
  margin-top: -25px !important;
}

.uploadExcel {
  border: 1px solid silver;
  margin-right: center;
  width: 230px;
  height: 85px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.imageBg {
  background-color: #f3e0d0;
  border-radius: 5px !important;
}

/* CSS */
.pagination-buttons {
  display: flex;
  justify-content: space-between;
}

.pagination-button:focus {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
  padding: 0;
  margin: 0;
  color: #007bff;
  /* You can set your desired text color */
  outline: none;
}

.pagination-button:disabled {
  cursor: not-allowed;
  color: #ccc;
  /* Change the color for disabled buttons */
  text-decoration: none;
  /* Remove underline for disabled buttons */
}

.excel-button {
  background-color: #007bff;
  /* Blue background color */
  color: white;
  /* White text color */
  border: none;
  /* Remove button border */
  padding: 10px 20px;
  /* Add padding for better appearance */
  cursor: pointer;
  /* Add pointer cursor on hover */
}

.excel-button:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
}

.buttons-excel {
  font-size: 0.9375rem;
  font-weight: 400;
  /* line-height: 1.5; */
  padding-bottom: 0.25rem 0.7rem;
  text-align: center;
  background-color: #6f42c1 !important;
  /* border-color: #fff !important; */
  color: #fff !important;
}

.ul-pagination {
  /* background-color:  #fff !important; */
  color: #6c757d;
  border: 1px solid #dee2e6;
  height: 35px !important;
  align-items: center;
  display: flex;
}

.borderleft-radius-5 {
  background-color: #fff !important;
  color: #6c757d;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border: 1px solid #dee2e6;
  align-items: center;
  height: 35px !important;
  display: flex;
}

.borderright-radius-5 {
  background-color: #fff !important;
  color: #6c757d;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #dee2e6;
  /* align-content: flex-end; */
  height: 35px !important;
  align-items: center;
  display: flex;
}

.div-button {
  border-radius: 10px;
  background-color: white;
  height: 10px;
  display: flex;
}

.th-font {
  font-weight: 500;
}

.thead-s {
  padding: 10px !important;
}

#farmOperations {
  background-color: #f7f8fa;
}

.borderNone td {
  border: none;
  width: auto;
  text-align: left;
  position: relative;
  left: 80px;
}

.react-datepicker-wrapper {
  width: 100%;
}


.peopledata-input {
  padding: 0.375rem 0.75rem !important;
}

.time-icon {
  width: 17px;
  float: right;
  bottom: 33px;
  position: relative;
  right: 16px;
}


.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0 !important;
  right: 15px;
  top: 0.7rem;
}

.react-datepicker-wrapper {
  width: 100%;
}

.time-input {
  padding: 0.375rem 0.75rem !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: black !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled:hover {
  color: black !important;
  cursor: not-allowed !important;
}

.form-check-input:disabled {
  cursor: not-allowed !important;
  pointer-events: initial !important;
  opacity: 1 !important;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  cursor: not-allowed !important;
  opacity: 1 !important;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  color: black !important;
}

.form-control:disabled {
  cursor: not-allowed !important;
  background-color: inherit !important;
  opacity: 1;
}

.chart-container canvas {
  height: 250px;
}

.chart-container14 {
  height: 430px !important;
}

#filtersInputs .form-control {
  width: 220px !important;
  font-size: 12px !important;
  margin-bottom: 1rem;
}

#filtersInputs .react-datepicker__close-icon {
  top: -6px !important;
}

.required {
  color: red !important;
  font-size: 20px;
  margin-left: 0.2rem;
}


select.form-control {
  -webkit-appearance: menulist;
}

.popupBtn {
  width: 70px !important;
}

.custom-width-btn {
  width: 100px !important;
}

.text-xs {
  font-size: 14px;
}

.custome-text-md {
  font-size: 15px;
}

.time-input-container {
  position: relative;
}

.time-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  /* Adjust the right position as needed */
  transform: translateY(-50%);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffff;
  opacity: 1;
}

.accordion.toggled .sidebar-brand {
  margin-left: 0 !important;
}

.farmoperation-textwidth {
  min-width: 114px;
}



@media print {
  .print-table-container {
    display: flex;
    flex-wrap: wrap;
  }

  .print-table {
    width: 100%;
    margin-bottom: 10px;
  }
}

.dropdown-togglereports {
  border-radius: 0.375rem !important;
  background-color: #ffff !important;
  font-size: 1rem !important;
  padding: 0.5rem !important;
  text-align: left !important;
  width: 100% !important;
  border: 1px solid #dee2e6 !important;
  color: #000 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.dropdown-search {
  width: 90% !important;
  margin: auto !important;
}

.dropdown-menu {
  width: 100% !important;
}

.manpowerTable {
  padding: 12px !important;
}

.farmoperationall-table {
  border: 2px solid #dee2e6 !important;
}

.dropdown-menu {
  width: 100% !important;
}

@media (min-width: 992px) {
  .modal-lg {
    --bs-modal-width: 820px !important;
  }
}

.active-row {
  background-color: #e6ffe6;
}

.inactive-row {
  background-color: #ffe6e6;
}

.label-style:after {
  content: " *";
  color: red;
  /* Optional: Set the color of the asterisk */
}

input,
input::placeholder {
  font: 0.88rem/3 sans-serif;
}

.form-select {
  color: #727983 !important;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
  background-color: #dddfeb;
  color: #000 !important;
}

@media (min-width: 768px) {
  .sidebar.toggled {
    overflow: hidden;
    width: 7.8rem !important;
  }
}

@media (min-width: 768px) {
  .sidebar.toggled .nav-item .nav-link span {
    font-size: 1rem;
    display: block;
  }
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
  padding: 0.5rem 0.5rem !important;
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
}

#dropdownMenuButton {
  word-wrap: break-word !important;
  word-break: break-all !important;
}

.rmsc .dropdown-heading {
  max-height: 200px !important;
  height: auto !important;
  min-height: auto;
  overflow-y: scroll;
  align-items: baseline !important;
}

.rmsc .dropdown-heading .dropdown-heading-value {
  white-space: pre-wrap !important;
}

.countStyle {
  font-size: 14px;
  font-weight: 600;
  color: rgb(32, 32, 32);
}
.chartname {
  font-size: 16px;
  font-weight: 700;
}
.dataNotAvailable {
  padding: 195px !important;
}
.barchat {
  height: 262px !important;
  margin-top: -20px;
}
.custom-datepicker {
  width: 120px !important; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  /* Add any other custom styles you need */
}
.custom-date {
  width: 120px !important; /* Adjust the width as needed */
  height: 30px;
  margin-left: -130px;
}

.icon-opacity {
  opacity: 0.6; 
  transition: opacity 0.2s;
}

.icon-opacity:hover {
  opacity: 1; 
}

.no-border {
  border-bottom: none !important;
}



/* Custom CSS for the toggle switch */
.react-toggle-track {
  background-color: #4CAF50; /* Green color */
}

.react-toggle-thumb {
  background-color: #fff; /* White thumb */
}

.react-toggle:checked .react-toggle-thumb {
  background-color: #4CAF50; /* Change thumb color when checked */
}

/* If you want to hide an icon that's part of form-control when invalid */
.form-control.is-invalid {
  background-image: none !important; 
}

